import React, { useEffect, useState, useGlobal } from "reactn";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Switch,
  TimePicker,
  Card,
  message,
  Select,
} from "antd";
import { useHistory } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import api from "../service/index";
import { Link } from "react-router-dom";
import { useCallback } from "react";
import { env } from "../app.config";
import {
  GroupPicker
} from '../components';
import "react-phone-input-2/lib/style.css";

const { Option } = Select;

const ServiceDetail = (props) => {
  let params = useParams();
  let history = useHistory();
  let id = params.id !== "add" ? params.id : false;
  let [languagesCms] = useGlobal("languagesCms");

  let newRecord = {
    deviceId: "",
    serviceName: "",
    status: true,
    firstTimePeriod: true,
    secondTimePeriod: false,
    thirdTimePeriod: false,
    fistTimeStart: "",
    fistTimeEnd: "",
    secondTimeStart:"",
    secondTimeEnd: "",
    thirdTimeStart: "",
    thirdTimeEnd: "",
    groups: [],
    deviceStatus: "faal",
    deviceStatusMessage: "",
  };

  let loginType = env.LOGINTYPE;
  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, setValidationCheck] = useState(false);
  let [isSmall,] = useGlobal("isSmall");

  let [modules] = useGlobal("modules");
  let path = props.location.pathname.split("/")[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  useEffect(() => {
    if (modules && id) {
      setLoading(true);
      api.get("/rest/services/" + id).then(({ data: { result, result_message } }) => {
        delete result.password;
        setData(result);
        setLoading(false);
      });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};

    errors.all = Object.getOwnPropertyNames(errors).map((n) => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => {
    if (validationCheck) setErrors(validate());
  }, [validationCheck, data, validate]);

  let save = async () => {
    setValidationCheck(true);
    let err = validate();
    console.log("data        ",data)
    if (err.hasError) {
      setErrors(err);
      window.scrollTo({ top: 20, behavior: "smooth" });
    } else {
      if (id) {
        api.put("/rest/services/" + id, data).then(({ data: { result, result_message } }) => {
          if (result_message.type === "success") {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push("/services");
          } else message.error(result_message.message, 2);
        });
      } else {
        api.post("/rest/services", data).then(({ data: { result, result_message } }) => {
          if (result_message.type === "success") {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push("/services");
          } else {
            message.error(result_message.message, 2);
          }
        });
      }
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ""}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/services">
            <Button type="light" icon={<LeftOutlined />} size={"large"}>
              {!isSmall && languagesCms.BACK}
            </Button>
          </Link>
        </div>
      </div>

      <div className="form-wrap">
        <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW} loading={loading}>
          <Form layout="horizontal" size={"large"} onFinish={save}>
            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.DEVICEID}
                  required
                  help={errors.deviceId}
                  validateStatus={errors.deviceId ? "error" : "success"}
                >
                  <Input
                    name="deviceId"
                    value={data.deviceId}
                    onChange={(e) => setData({ ...data, deviceId: e.target.value })}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.DEVICENAME}
                  required
                  help={errors.serviceName}
                  validateStatus={errors.serviceName ? "error" : "success"}
                >
                  <Input
                    name="serviceName"
                    value={data.serviceName}
                    onChange={(e) => setData({ ...data, serviceName: e.target.value })}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  label={languagesCms.STATUS}
                  help={errors.status}
                  validateStatus={errors.status ? "error" : "success"}
                >
                  <Switch
                    checked={data.status}
                    onChange={(value) => setData({ ...data, status: value })}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item
                  label={languagesCms.GROUP}
                  help={errors.groups}
                  validateStatus={errors.active ? 'error' : 'success'}
                >
                  <GroupPicker
                    languagesCms={languagesCms}
                    record={data}
                    setRecord={setData}
                    name="groups"
                  />
                </Form.Item>
              </Col>

            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.FIRSTTIMESTART}>
                  {data.firstTimePeriod && (
                    <TimePicker
  value={data.fistTimeStart ? moment(data.fistTimeStart, "HH:mm") : null}
  onChange={(value) => setData({ ...data, fistTimeStart: value ? moment(value).format("HH:mm") : "" })}
  format="HH:mm"
/>

                  )}
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.FIRSTTIMEEND}>
                  {data.firstTimePeriod && (
                    <TimePicker
                      value={data.fistTimeEnd ? moment(data.fistTimeEnd, "HH:mm") : null}
                      onChange={(value) => setData({ ...data, fistTimeEnd: value ? moment(value).format("HH:mm") : "" })}
                      format="HH:mm"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.SECONDTIMEPERIOD}>
                  <Switch
                    checked={data.secondTimePeriod}
                    onChange={(value) => setData({ ...data, secondTimePeriod: value })}
                  />
                </Form.Item>
              </Col>
            </Row>
            {data.secondTimePeriod && (
              <>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item label={languagesCms.FIRSTTIMESTART}>
                      <TimePicker
                        value={data.secondTimeStart ? moment(data.secondTimeStart, "HH:mm") : null}
                        onChange={(value) => setData({ ...data, secondTimeStart: value ? moment(value).format("HH:mm") : "" })}
                        format="HH:mm"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item label={languagesCms.FIRSTTIMEEND}>
                      <TimePicker
                        value={data.secondTimeEnd ? moment(data.secondTimeEnd, "HH:mm") : null}
                        onChange={(value) => setData({ ...data, secondTimeEnd: value ? moment(value).format("HH:mm") : "" })}
                        format="HH:mm"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.THIRDTIMEPERIOD}>
                  <Switch
                    checked={data.thirdTimePeriod}
                    onChange={(value) => setData({ ...data, thirdTimePeriod: value })}
                  />
                </Form.Item>
              </Col>
            </Row>
            {data.thirdTimePeriod && (
              <>
                <Row direction="row">
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item label={languagesCms.FIRSTTIMESTART}>
                      <TimePicker
                        value={data.thirdTimeStart ? moment(data.thirdTimeStart, "HH:mm") : null}
                        onChange={(value) => setData({ ...data, thirdTimeStart: value ? moment(value).format("HH:mm") : "" })}
                        format="HH:mm"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.Item label={languagesCms.FIRSTTIMEEND}>
                      <TimePicker
                        value={data.thirdTimeEnd ? moment(data.thirdTimeEnd, "HH:mm") : null}
                        onChange={(value) => setData({ ...data, thirdTimeEnd: value ? moment(value).format("HH:mm") : "" })}
                        format="HH:mm"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.DEVICESTATUS}>
                  <Select
                    value={data.deviceStatus}
                    onChange={(value) => setData({ ...data, deviceStatus: value })}
                  >
                    <Option value="faal">Faal</Option>
                    <Option value="inactive">Inactive</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={languagesCms.DEVICESTATUSMESSAGE}>
                  <Input
                    value={data.deviceStatusMessage}
                    onChange={(e) => setData({ ...data, deviceStatusMessage: e.target.value })}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    disabled={loading}
                    htmlType="submit"
                    size="large"
                    block
                  >
                    {languagesCms.SAVE}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default ServiceDetail;
